/**
 * @generated SignedSource<<ccb1a7dbe5634e6ac69fe51ed3905449>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateEmailTemplateInput = {
  body?: string | null;
  ctaText: string;
  emailTemplateId: string;
  richEditorBody?: string | null;
  subject: string;
  title?: string | null;
};
export type EditEmailTemplateFormMutation$variables = {
  input: UpdateEmailTemplateInput;
};
export type EditEmailTemplateFormMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly body: string;
      readonly ctaText: string;
      readonly id: string;
      readonly richEditorBody: string | null;
      readonly subject: string;
      readonly title: string;
    } | null;
  };
};
export type EditEmailTemplateFormMutation = {
  response: EditEmailTemplateFormMutation$data;
  variables: EditEmailTemplateFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateEmailTemplateResponse",
    "kind": "LinkedField",
    "name": "updateEmailTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailTemplate",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "richEditorBody",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctaText",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEmailTemplateFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditEmailTemplateFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "691ce6e2f09f84c7d9860f74e49999e9",
    "id": null,
    "metadata": {},
    "name": "EditEmailTemplateFormMutation",
    "operationKind": "mutation",
    "text": "mutation EditEmailTemplateFormMutation(\n  $input: UpdateEmailTemplateInput!\n) {\n  response: updateEmailTemplate(input: $input) {\n    node {\n      id\n      subject\n      title\n      body\n      richEditorBody\n      ctaText\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4398bccd15ada3465a51d20307e8fad5";

export default node;
