/**
 * @generated SignedSource<<acc31054643a8b7ca3e60067d288c164>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMemberGroupButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "EditMemberGroupButtonFragment";
};
export type EditMemberGroupButtonFragment$key = {
  readonly " $data"?: EditMemberGroupButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditMemberGroupButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditMemberGroupButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};

(node as any).hash = "9eed1456419de050f08ae8c0c7ae4b31";

export default node;
