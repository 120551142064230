import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ProductMembershipUserShape } from "@/product/course/curriculum/common/modal/CurriculumProfileListModalContent"
import { CurriculumProfileListModalListItemFragment$key } from "@/product/course/curriculum/common/modal/item/__generated__/CurriculumProfileListModalListItemFragment.graphql"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { getUserFullName } from "@/user/util/userUtils"
import { DiscoCheckbox, DiscoText } from "@disco-ui"
import React from "react"
import { graphql, useFragment } from "react-relay"

interface CurriculumProfileListModalListItemProps {
  productMembershipKey: CurriculumProfileListModalListItemFragment$key
  testid?: string
  isSelected?: boolean
  setSelectedProductMembers: React.Dispatch<
    React.SetStateAction<ProductMembershipUserShape[]>
  >
}

function CurriculumProfileListModalListItem({
  productMembershipKey,
  testid,
  isSelected = false,
  setSelectedProductMembers,
}: CurriculumProfileListModalListItemProps) {
  const classes = useStyles()

  const productMembership = useFragment<CurriculumProfileListModalListItemFragment$key>(
    graphql`
      fragment CurriculumProfileListModalListItemFragment on ProductMembership {
        id
        member {
          firstName
          fullName
          ...ProfileAvatarFragment
        }
      }
    `,
    productMembershipKey
  )

  return (
    <div className={classes.itemContainer} data-testid={testid}>
      <div className={classes.checkboxContainer}>
        <DiscoCheckbox
          testid={"CurriculumProfileListModalListItem.select-button"}
          label={null}
          onChange={handleSelectUser}
          checked={isSelected}
        />
      </div>

      {/* Avatar */}
      <ProfileAvatar
        testid={`${testid}.MultipleAvatars.${productMembership.member.firstName}`}
        userKey={productMembership.member}
        size={40}
      />

      <div className={classes.userContainer}>
        {/* Name */}
        <DiscoText className={classes.nameContainer} testid={`${testid}.name`}>
          {productMembership.member.firstName
            ? productMembership.member.fullName
            : getUserFullName({ first_name: null, last_name: null })}
        </DiscoText>
      </div>
    </div>
  )

  function handleSelectUser() {
    setSelectedProductMembers((selectedUsers) => {
      if (isSelected) {
        return selectedUsers.filter((u) => u.id !== productMembership.id)
      }
      return [...selectedUsers, { id: productMembership.id, ...productMembershipKey }]
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1.5),

    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
  checkboxContainer: {
    marginRight: theme.spacing(-0.25),
  },
  userContainer: {
    padding: theme.spacing(0, 0.75),
  },
  nameContainer: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
}))

export default CurriculumProfileListModalListItem
