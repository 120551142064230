/**
 * @generated SignedSource<<a4a46f7d14721f0305940de1fb3f6f8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageSubmissionVisibility = "cohort" | "member_group" | "private" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SubmitAssignmentFormFragment$data = {
  readonly confirmationModalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"CurriculumConfirmationModalConfirmationModalFragment">;
  } | null;
  readonly dueAt: string | null;
  readonly id: string;
  readonly submissionVisibility: ContentUsageSubmissionVisibility | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useSubmissionDeadlineFragment" | "SubmitAssignmentFormFieldsFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "SubmitAssignmentFormFragment";
};
export type SubmitAssignmentFormFragment$key = {
  readonly " $data"?: SubmitAssignmentFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmitAssignmentFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmitAssignmentFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionVisibility",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitAssignmentFormFieldsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useSubmissionDeadlineFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "confirmationModalContent",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CurriculumConfirmationModalConfirmationModalFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "c3b14f4f315eb790d5382fb9980bcb37";

export default node;
