import CreateContentModuleButton from "@/content-usage/modules/actions/CreateContentModuleButton"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { CurriculumHeaderCreateButtonQuery } from "@/product/course/curriculum/common/header/components/create-button/__generated__/CurriculumHeaderCreateButtonQuery.graphql"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface CurriculumHeaderCreateButtonProps {
  className?: string
}

function CurriculumHeaderCreateButton({ className }: CurriculumHeaderCreateButtonProps) {
  const activeProduct = useActiveProduct()!
  const isMobile = useIsMobile()

  const { product } = useLazyLoadQuery<CurriculumHeaderCreateButtonQuery>(
    graphql`
      query CurriculumHeaderCreateButtonQuery($id: ID!) {
        product: node(id: $id) {
          id
          ... on Product {
            curriculum {
              id
            }
          }
        }
      }
    `,
    { id: activeProduct.id },
    {
      fetchPolicy: "store-and-network",
    }
  )

  const curriculum = product?.curriculum
  const { hasIncompleteGeneration } = useTrackAIGenerationStatus({
    entityId: curriculum?.id || "",
  })

  if (!curriculum) return null

  return (
    <span className={className}>
      <CreateContentModuleButton
        testid={"CreateCurriculumSectionButton"}
        parentEntityId={curriculum!.id}
        parentEntity={"curriculum"}
        showAddIcon
        disabled={hasIncompleteGeneration}
      >
        {isMobile ? "Add" : "Add Module"}
      </CreateContentModuleButton>
    </span>
  )
}

export default CurriculumHeaderCreateButton
