/**
 * @generated SignedSource<<4c8a5ac8140ced15a42c201ef9aaf84f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseGroupsListFragment$data = {
  readonly defaultMemberGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateMemberGroupButtonFragment">;
  };
  readonly id: string;
  readonly memberGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"CourseGroupsListItemFragment">;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "CourseGroupsListFragment";
};
export type CourseGroupsListFragment$key = {
  readonly " $data"?: CourseGroupsListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseGroupsListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseGroupsListFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroup",
      "kind": "LinkedField",
      "name": "defaultMemberGroup",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateMemberGroupButtonFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "custom"
        }
      ],
      "concreteType": "MemberGroupNodeConnection",
      "kind": "LinkedField",
      "name": "memberGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CourseGroupsListItemFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "memberGroups(kind:\"custom\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "1bdcbb231876c707214e2550f8bfef0f";

export default node;
