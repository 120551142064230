/**
 * @generated SignedSource<<4444d2a0e0d7f0ea4043b3b245f14a3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurriculumProfileListModalListItemFragment$data = {
  readonly id: string;
  readonly member: {
    readonly firstName: string | null;
    readonly fullName: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  };
  readonly " $fragmentType": "CurriculumProfileListModalListItemFragment";
};
export type CurriculumProfileListModalListItemFragment$key = {
  readonly " $data"?: CurriculumProfileListModalListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumProfileListModalListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurriculumProfileListModalListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "member",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};

(node as any).hash = "85461119a2e1df6ae0040891aeec178b";

export default node;
