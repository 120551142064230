import { useLabel } from "@/core/context/LabelsContext"
import CurriculumProfileListModalContent from "@/product/course/curriculum/common/modal/CurriculumProfileListModalContent"
import CurriculumProfileListModalTabList, {
  CurriculumProfileListModalTab,
} from "@/product/course/curriculum/common/modal/tab/CurriculumProfileListModalTabList"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoDivider, DiscoModal } from "@disco-ui"
import { useState } from "react"

interface CurriculumProfileListModalProps {
  isOpen: boolean
  onClose: () => void
  contentUsageId: GlobalID
}

function CurriculumProfileListModal({
  isOpen,
  onClose,
  contentUsageId,
}: CurriculumProfileListModalProps) {
  const [activeTab, setActiveTab] = useState<CurriculumProfileListModalTab>("completed")
  const membersLabel = useLabel("product_member")

  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={onClose}
      modalContentLabel={"Completed Users"}
      testid={"CurriculumProfileListModal"}
      title={"Completion Status"}
      buttons
      subtitle={`You can email specific ${membersLabel.plural} by selecting the checkboxes and clicking the “Send Emails” button to launch your mail program.`}
      body={
        <>
          <div>
            <CurriculumProfileListModalTabList
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <DiscoDivider marginBottom={0} marginTop={0} />
          </div>
          {isOpen ? (
            <CurriculumProfileListModalContent
              activeTab={activeTab}
              contentUsageId={contentUsageId}
            />
          ) : null}
        </>
      }
    />
  )
}

export default CurriculumProfileListModal
