/**
 * @generated SignedSource<<b1e9d6ee01e2a36ee9271a82df9c86b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentUsageSubmissionVisibility = "cohort" | "member_group" | "private" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SubmitAssignmentFormFieldsFragment$data = {
  readonly id: string;
  readonly submissionFormat: {
    readonly file: {
      readonly enabled: boolean;
      readonly required: boolean;
    };
    readonly link: {
      readonly enabled: boolean;
      readonly required: boolean;
    };
    readonly text: {
      readonly enabled: boolean;
      readonly required: boolean;
    };
  } | null;
  readonly submissionVisibility: ContentUsageSubmissionVisibility | null;
  readonly " $fragmentType": "SubmitAssignmentFormFieldsFragment";
};
export type SubmitAssignmentFormFieldsFragment$key = {
  readonly " $data"?: SubmitAssignmentFormFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmitAssignmentFormFieldsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "required",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmitAssignmentFormFieldsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionVisibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmissionFormat",
      "kind": "LinkedField",
      "name": "submissionFormat",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentSubmissionFormatSettings",
          "kind": "LinkedField",
          "name": "text",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentSubmissionFormatSettings",
          "kind": "LinkedField",
          "name": "file",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentSubmissionFormatSettings",
          "kind": "LinkedField",
          "name": "link",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "de47988bcde8f7ab47e373c749b4e033";

export default node;
