import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CreateMemberGroupButton from "@/product/common/member-group/common/create/button/CreateMemberGroupButton"
import { AddMembersToGroupsModalMutation } from "@/product/common/member-group/modal/__generated__/AddMembersToGroupsModalMutation.graphql"
import { AddMembersToGroupsModalQuery } from "@/product/common/member-group/modal/__generated__/AddMembersToGroupsModalQuery.graphql"
import MemberGroupsMultiSelect from "@/product/common/member-group/modal/components/MemberGroupsMultiSelect"
import SelectGroupMembersList from "@/product/common/member-group/modal/components/SelectGroupMembersList"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoModal, DiscoTextButton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface AddMembersToGroupsModal {
  memberships: { id: GlobalID; memberId: GlobalID }[]
  isModalOpen: boolean
  onClose: () => void
  onAdd: () => void
}

function AddMembersToGroupsModal({
  memberships,
  isModalOpen,
  onClose,
  onAdd,
}: AddMembersToGroupsModal) {
  const [selectedMembershipIds, setSelectedMembershipIds] = useState<GlobalID[]>(
    memberships.map((m) => m.id)
  )
  const [selectedGroupIds, setSelectedGroupIds] = useState<GlobalID[]>([])
  const experienceLabel = useLabel("experience")
  const activeProduct = useActiveProduct()!
  const classes = useStyles()

  const { node } = useLazyLoadQuery<AddMembersToGroupsModalQuery>(
    graphql`
      query AddMembersToGroupsModalQuery($productId: ID!, $membershipIds: [ID!]) {
        node(id: $productId) {
          ... on Product {
            id
            defaultMemberGroup {
              ...CreateMemberGroupButtonFragment
            }
            ...SelectGroupMembersListFragment @arguments(membershipIds: $membershipIds)
            ...MemberGroupsMultiSelect_ProductFragment
          }
        }
      }
    `,
    { productId: activeProduct.id, membershipIds: memberships.map((m) => m.id) }
  )

  const form = useFormStore<AddMembersToGroupsModalMutation>(
    graphql`
      mutation AddMembersToGroupsModalMutation($input: AddUsersToMemberGroupInput!) {
        response: addUsersToMemberGroup(input: $input) {
          memberGroupMemberships {
            edges {
              node {
                productMembership {
                  ...useMemberGroupTagsList_ProductMembershipFragment
                }
                organizationMembership {
                  ...useMemberGroupTagsList_OrganizationMembershipFragment
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      userIds: [],
      memberGroupIds: [],
      productId: activeProduct.id,
    }
  )

  if (!node) return null

  return (
    <DiscoModal
      isOpen={isModalOpen}
      onClose={onClose}
      testid={"AddMembersToGroupModal"}
      modalContentLabel={"Add Members to Group"}
      title={"Add Members to Group"}
      subtitle={`Add ${selectedMembershipIds.length} selected ${pluralize(
        "member",
        selectedMembershipIds.length
      )} to a Group to share specific events, assignments and ${
        experienceLabel.plural
      } with them.`}
      body={
        <>
          <SelectGroupMembersList
            productKey={node}
            selectedMembershipIds={selectedMembershipIds}
            setSelectedMembershipIds={setSelectedMembershipIds}
          />

          <MemberGroupsMultiSelect
            selectedGroupIds={selectedGroupIds}
            setSelectedGroupIds={setSelectedGroupIds}
            productKey={node}
          />
        </>
      }
      fullWidthButtons
      buttons={
        <>
          {/* Add Group Button */}
          <CreateMemberGroupButton
            isCreatingSubGroup
            groupKey={node.defaultMemberGroup!}
            selectedMembershipIds={memberships.map((m) => m.id)}
            onCreate={handleClose}
          >
            {({ onClick }) => (
              <DiscoTextButton
                data-testid={"AddMembersToGroupsModal.create-group-button"}
                leftIcon={"add"}
                onClick={onClick}
                className={classes.newGroup}
              >
                {"New Group"}
              </DiscoTextButton>
            )}
          </CreateMemberGroupButton>

          {/* Cancel Button */}
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
            {"Cancel"}
          </DiscoButton>

          {/* Add to Group Button */}
          <DiscoButton
            onClick={addToMemberGroup}
            disabled={
              form.isSubmitting ||
              selectedGroupIds.length === 0 ||
              selectedMembershipIds.length === 0
            }
            shouldDisplaySpinner={form.isSubmitting}
            data-testid={"MemberGroupFormFields.submit"}
          >
            {"Add to Group"}
          </DiscoButton>
        </>
      }
    />
  )

  function handleClose() {
    onClose()
    onAdd()
  }

  async function addToMemberGroup() {
    const { didSave } = await form.submit({
      ...form.state,
      userIds: memberships
        .filter((pm) => selectedMembershipIds.includes(pm.id))
        .map((pm) => pm.memberId),
      memberGroupIds: selectedGroupIds,
    })
    if (!didSave) return

    displaySuccessToast({
      message: `Added to ${pluralize("group", selectedGroupIds.length)}!`,
      testid: "AddMembersToGroupsModal.success-toast",
    })
    onAdd()
  }
}

const useStyles = makeUseStyles({
  newGroup: {
    marginRight: "auto",
  },
})

export default Relay.withSkeleton<AddMembersToGroupsModal>({
  component: observer(AddMembersToGroupsModal),
  skeleton: () => null,
})
