/**
 * @generated SignedSource<<218d82dffb837ac34b9081ee40de649f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OccurrenceHostsLabelQuery$variables = {
  id: string;
};
export type OccurrenceHostsLabelQuery$data = {
  readonly occurrence: {
    readonly hosts?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly user: {
            readonly firstName: string | null;
            readonly fullName: string;
            readonly id: string;
            readonly lastName: string | null;
            readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileListDrawerListItemFragment" | "ProfileListModalListItemFragment">;
          };
        };
      }>;
      readonly totalCount: number;
    };
  } | null;
};
export type OccurrenceHostsLabelQuery = {
  response: OccurrenceHostsLabelQuery$data;
  variables: OccurrenceHostsLabelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OccurrenceHostsLabelQuery",
    "selections": [
      {
        "alias": "occurrence",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OccurrenceHostNodeConnection",
                "kind": "LinkedField",
                "name": "hosts",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceHostNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OccurrenceHost",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProfileListDrawerListItemFragment"
                              },
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProfileAvatarFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ProfileListModalListItemFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Occurrence",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OccurrenceHostsLabelQuery",
    "selections": [
      {
        "alias": "occurrence",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OccurrenceHostNodeConnection",
                "kind": "LinkedField",
                "name": "hosts",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OccurrenceHostNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OccurrenceHost",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Occurrence",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0043fd496e86b8fdf7ff280274649021",
    "id": null,
    "metadata": {},
    "name": "OccurrenceHostsLabelQuery",
    "operationKind": "query",
    "text": "query OccurrenceHostsLabelQuery(\n  $id: ID!\n) {\n  occurrence: node(id: $id) {\n    __typename\n    ... on Occurrence {\n      hosts {\n        totalCount\n        edges {\n          node {\n            id\n            user {\n              fullName\n              ...ProfileListDrawerListItemFragment\n              id\n              firstName\n              lastName\n              ...ProfileAvatarFragment\n              ...ProfileListModalListItemFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileListDrawerListItemFragment on User {\n  id\n  firstName\n  lastName\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment ProfileListModalListItemFragment on User {\n  id\n  firstName\n  lastName\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "53f7769c76d84e673ee4c1f229d0937f";

export default node;
