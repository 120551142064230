import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { EditMemberGroupButtonFragment$key } from "@/product/common/member-group/common/edit/button/__generated__/EditMemberGroupButtonFragment.graphql"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  groupKey: EditMemberGroupButtonFragment$key
  children: OverridableDiscoButtonChildren
}

const EditMemberGroupButton: React.FC<Props> = (props) => {
  const { children, groupKey } = props
  const drawer = useGlobalDrawer("memberGroup")

  const memberGroup = useFragment<EditMemberGroupButtonFragment$key>(
    graphql`
      fragment EditMemberGroupButtonFragment on MemberGroup {
        id
      }
    `,
    groupKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={handleOpenDrawer}>
        {children}
      </OverridableDiscoButton>
    </>
  )

  function handleOpenDrawer() {
    drawer.open({
      memberGroupId: memberGroup.id,
      tab: "settings",
    })
  }
}

export default EditMemberGroupButton
