/**
 * @generated SignedSource<<e5ad31fe547215ecf1f02586019ecf2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CurriculumHeaderCreateButtonQuery$variables = {
  id: string;
};
export type CurriculumHeaderCreateButtonQuery$data = {
  readonly product: {
    readonly curriculum?: {
      readonly id: string;
    } | null;
    readonly id: string;
  } | null;
};
export type CurriculumHeaderCreateButtonQuery = {
  response: CurriculumHeaderCreateButtonQuery$data;
  variables: CurriculumHeaderCreateButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Curriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurriculumHeaderCreateButtonQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurriculumHeaderCreateButtonQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d99863332efdca57db177a7e3abffa6",
    "id": null,
    "metadata": {},
    "name": "CurriculumHeaderCreateButtonQuery",
    "operationKind": "query",
    "text": "query CurriculumHeaderCreateButtonQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    id\n    ... on Product {\n      curriculum {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "02165197acf0ca60133d76ea3b82c289";

export default node;
