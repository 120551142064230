/**
 * @generated SignedSource<<f94d8e636eedceae6fc6e52e0729bda2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FlagContentButtonFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FlagContentFormFragment" | "useCanFlagContentFragment">;
  readonly " $fragmentType": "FlagContentButtonFragment";
};
export type FlagContentButtonFragment$key = {
  readonly " $data"?: FlagContentButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FlagContentButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlagContentButtonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCanFlagContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FlagContentFormFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "ece18acebbc9765c35a23ec9e74ce405";

export default node;
