/**
 * @generated SignedSource<<003584e51e835a34c934c7c580a803f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentFlaggingReason = "abusive" | "community_policy" | "other" | "spam" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentFlaggingListItemFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly reason: ContentFlaggingReason;
  readonly reasonText: string | null;
  readonly user: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  };
  readonly " $fragmentType": "ContentFlaggingListItemFragment";
};
export type ContentFlaggingListItemFragment$key = {
  readonly " $data"?: ContentFlaggingListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentFlaggingListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentFlaggingListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reasonText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentFlagging",
  "abstractKey": null
};
})();

(node as any).hash = "1bf42b18a9d452fc322ef836c8c94ecb";

export default node;
