/**
 * @generated SignedSource<<9bc95e84b7bcd906fb8e2cacef20822e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCompleteProfileCurriculumItemsInput = {
  userId?: string | null;
};
export type useUpdateCompleteProfileItemsMutation$variables = {
  input: UpdateCompleteProfileCurriculumItemsInput;
  productId: string;
};
export type useUpdateCompleteProfileItemsMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly productMembership: {
        readonly product: {
          readonly curriculum: {
            readonly completeProfileContentUsage: {
              readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_ContentCompletionFragment">;
            } | null;
          } | null;
          readonly id: string;
        };
      } | null;
    } | null;
  } | null;
};
export type useUpdateCompleteProfileItemsMutation = {
  response: useUpdateCompleteProfileItemsMutation$data;
  variables: useUpdateCompleteProfileItemsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "productId",
    "variableName": "productId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasCompleted",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLocked",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentUsageNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentUsage",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateCompleteProfileItemsMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateCompleteProfileCurriculumItemsResponse",
        "kind": "LinkedField",
        "name": "updateCompleteProfileCurriculumItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductMembership",
                "kind": "LinkedField",
                "name": "productMembership",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Curriculum",
                        "kind": "LinkedField",
                        "name": "curriculum",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsage",
                            "kind": "LinkedField",
                            "name": "completeProfileContentUsage",
                            "plural": false,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ContentUsageUtils_ContentCompletionFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateCompleteProfileItemsMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateCompleteProfileCurriculumItemsResponse",
        "kind": "LinkedField",
        "name": "updateCompleteProfileCurriculumItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductMembership",
                "kind": "LinkedField",
                "name": "productMembership",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Curriculum",
                        "kind": "LinkedField",
                        "name": "curriculum",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsage",
                            "kind": "LinkedField",
                            "name": "completeProfileContentUsage",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentUsageNodeConnection",
                                "kind": "LinkedField",
                                "name": "dependents",
                                "plural": false,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Content",
                                "kind": "LinkedField",
                                "name": "module",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ContentUsageNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "usages",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ContentUsageNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ContentUsage",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ContentUsageNodeConnection",
                                                "kind": "LinkedField",
                                                "name": "dependents",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ContentUsageNodeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ContentUsage",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v2/*: any*/),
                                                          (v5/*: any*/),
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Content",
                                                            "kind": "LinkedField",
                                                            "name": "content",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ContentUsageNodeConnection",
                                                                "kind": "LinkedField",
                                                                "name": "children",
                                                                "plural": false,
                                                                "selections": (v6/*: any*/),
                                                                "storageKey": null
                                                              },
                                                              (v2/*: any*/)
                                                            ],
                                                            "storageKey": null
                                                          }
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              },
                                              (v4/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProductMembershipNodeConnection",
                                                "kind": "LinkedField",
                                                "name": "completedProductMemberships",
                                                "plural": false,
                                                "selections": [
                                                  (v7/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ProductMembershipNodeEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ProductMembership",
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v2/*: any*/),
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "member",
                                                            "plural": false,
                                                            "selections": [
                                                              (v2/*: any*/),
                                                              {
                                                                "alias": "first_name",
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "firstName",
                                                                "storageKey": null
                                                              },
                                                              {
                                                                "alias": "last_name",
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "lastName",
                                                                "storageKey": null
                                                              },
                                                              {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "avatar",
                                                                "storageKey": null
                                                              }
                                                            ],
                                                            "storageKey": null
                                                          }
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ContentCompletionNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "viewerChildContentCompletions",
                                    "plural": false,
                                    "selections": (v8/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductMembershipNodeConnection",
                                "kind": "LinkedField",
                                "name": "completedProductMemberships",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d41cb3b3e0fd7fa9e0dfb45d7f28342",
    "id": null,
    "metadata": {},
    "name": "useUpdateCompleteProfileItemsMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateCompleteProfileItemsMutation(\n  $input: UpdateCompleteProfileCurriculumItemsInput!\n  $productId: ID!\n) {\n  response: updateCompleteProfileCurriculumItems(input: $input) {\n    node {\n      id\n      productMembership(productId: $productId) {\n        product {\n          id\n          curriculum {\n            completeProfileContentUsage {\n              ...ContentUsageUtils_ContentCompletionFragment\n              id\n            }\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment ContentUsageUtils_ContentCompletionFragment on ContentUsage {\n  id\n  viewerHasCompleted\n  dependents {\n    edges {\n      node {\n        id\n        isLocked\n      }\n    }\n  }\n  module {\n    id\n    usages {\n      edges {\n        node {\n          id\n          dependents {\n            edges {\n              node {\n                id\n                isLocked\n                content {\n                  children {\n                    edges {\n                      node {\n                        id\n                        isLocked\n                      }\n                    }\n                  }\n                  id\n                }\n              }\n            }\n          }\n          viewerHasCompleted\n          completedProductMemberships {\n            totalCount\n            edges {\n              node {\n                id\n                member {\n                  id\n                  first_name: firstName\n                  last_name: lastName\n                  avatar\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    viewerChildContentCompletions {\n      totalCount\n    }\n  }\n  completedProductMemberships {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad239ec5c5310a5ff9593cf0796aa825";

export default node;
