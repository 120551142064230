/**
 * @generated SignedSource<<c1b5c5c390aaaf5cc6f1fd3c2a625ce5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentFlaggingReason = "abusive" | "community_policy" | "other" | "spam" | "%future added value";
export type FlagContentInput = {
  contentId: string;
  reason: ContentFlaggingReason;
  reasonText?: string | null;
};
export type FlagContentFormMutation$variables = {
  input: FlagContentInput;
};
export type FlagContentFormMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly content: {
        readonly " $fragmentSpreads": FragmentRefs<"ContentFlaggingsChipFragment" | "FlagContentButtonFragment">;
      };
      readonly id: string;
    } | null;
  };
};
export type FlagContentFormMutation = {
  response: FlagContentFormMutation$data;
  variables: FlagContentFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FlagContentFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "FlagContentResponse",
        "kind": "LinkedField",
        "name": "flagContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentFlagging",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FlagContentButtonFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContentFlaggingsChipFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FlagContentFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "FlagContentResponse",
        "kind": "LinkedField",
        "name": "flagContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentFlagging",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerHasFlagged",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentFlaggingNodeConnection",
                    "kind": "LinkedField",
                    "name": "flaggings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentFlaggingNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentFlagging",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reason",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reasonText",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "fullName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "avatar",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isTest",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34e9bb7536812e1db3edc8022db7f5d6",
    "id": null,
    "metadata": {},
    "name": "FlagContentFormMutation",
    "operationKind": "mutation",
    "text": "mutation FlagContentFormMutation(\n  $input: FlagContentInput!\n) {\n  response: flagContent(input: $input) {\n    node {\n      id\n      content {\n        ...FlagContentButtonFragment\n        ...ContentFlaggingsChipFragment\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment ContentFlaggingListItemFragment on ContentFlagging {\n  id\n  reason\n  reasonText\n  createdAt\n  user {\n    id\n    ...ProfileAvatarWithDetailsFragment\n  }\n}\n\nfragment ContentFlaggingListPopoverFragment on Content {\n  flaggings {\n    edges {\n      node {\n        id\n        ...ContentFlaggingListItemFragment\n      }\n    }\n  }\n}\n\nfragment ContentFlaggingsChipFragment on Content {\n  flaggings {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  ...ContentFlaggingListPopoverFragment\n}\n\nfragment FlagContentButtonFragment on Content {\n  ...useCanFlagContentFragment\n  ...FlagContentFormFragment\n}\n\nfragment FlagContentFormFragment on Content {\n  id\n  type\n  product {\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment useCanFlagContentFragment on Content {\n  viewerHasFlagged\n  createdBy {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2469b4e7987b2316d288b3a27b2a6890";

export default node;
