import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { CourseGroupsListItemAvatarStackFragment$key } from "@/product/course/settings/groups-settings/list/item/__generated__/CourseGroupsListItemAvatarStackFragment.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatarStack, {
  ProfileAvatarStackSkeleton,
} from "@/user/common/avatar-stack/ProfileAvatarStack"
import { DiscoTextSkeleton } from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import pluralize from "pluralize"
import React from "react"
import { graphql, useFragment } from "react-relay"

interface Props {
  groupKey: CourseGroupsListItemAvatarStackFragment$key
  testid: string
  hideCount?: boolean
}

const CourseGroupsListItemAvatarStack: React.FC<Props> = (props) => {
  const drawer = useGlobalDrawer("memberGroup")
  const { groupKey, testid, hideCount = false } = props

  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))
  const classes = useStyles()

  const memberGroup = useFragment<CourseGroupsListItemAvatarStackFragment$key>(
    graphql`
      fragment CourseGroupsListItemAvatarStackFragment on MemberGroup {
        id
        name
        sampleMemberships: memberGroupMemberships(first: 3) {
          totalCount
          edges {
            node {
              id
              user {
                ...ProfileAvatarStackFragment @relay(mask: false)
              }
            }
          }
        }
      }
    `,
    groupKey
  )

  const memberCount = memberGroup.sampleMemberships.totalCount
  const memberships = Relay.connectionToArray(memberGroup.sampleMemberships)

  return (
    <div className={classes.container} data-testid={`${testid}.avatar-stack`}>
      {isMdUp && (
        <>
          <ProfileAvatarStack
            testid={"CourseGroupsListItemAvatarStack"}
            users={memberships.map((member) => member.user)}
            stackSize={3}
            totalUsers={memberCount}
            totalUsersCopy={
              hideCount ? undefined : `${pluralize("member", memberCount, true)}`
            }
            avatarSize={32}
            onClick={() => drawer.open({ memberGroupId: memberGroup.id, tab: "details" })}
          />
        </>
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
}))

export const CourseGroupsListItemAvatarStackSkeleton: React.FC<
  Omit<Props, "groupKey" | "testid">
> = ({ hideCount }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <ProfileAvatarStackSkeleton />
      {!hideCount && <DiscoTextSkeleton width={"90px"} />}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: CourseGroupsListItemAvatarStack,
  skeleton: CourseGroupsListItemAvatarStackSkeleton,
})
