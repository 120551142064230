import MemberGroupAvatar from "@/admin/members/groups/MemberGroupAvatar"
import FormStore from "@/core/form/store/FormStore"
import MediaLibraryButton from "@/media/add/MediaLibraryButton"
import { CreateMemberGroupFormState } from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import { EditMemberGroupFormState } from "@/product/common/member-group/util/MemberGroupUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton, DiscoFormControl, DiscoTextButton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useCallback } from "react"

type Props = {
  form: FormStore<CreateMemberGroupFormState> | FormStore<EditMemberGroupFormState>
}
function MemberGroupAvatarInput({ form }: Props) {
  const hoverStyles = useShowOnHoverStyles()
  const classes = useStyles()
  return (
    <DiscoFormControl>
      <div className={classes.container}>
        <MediaLibraryButton
          onUpload={handleChangeImage}
          dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
          allowedFileTypes={["image"]}
          suggestedDimensions={{ width: 120, height: 120 }}
          enforceSuggestedDimensions
          // message={"Drop or upload an image"}
          testid={"InlineCoverPhotoForm.change-image-button"}
          onMediaSelect={handleChangeImage}
          cropperProps={{
            stencilProps: { aspectRatio: ASPECT_RATIOS.SQUARE },
          }}
          PopoverProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          color={"transparent"}
        >
          {useCallback(
            (btnProps) => (
              <DiscoContainerButton
                className={classNames(hoverStyles.hoverable, classes.avatarContainer)}
                onClick={btnProps.onClick}
                disabled={btnProps.disabled}
              >
                <MemberGroupAvatar
                  memberGroup={{
                    avatarURL: form.state.avatarURL ?? null,
                    color: form.state.color!,
                    name: form.state.name!,
                    kind: form.state.kind!,
                    product: form.state.product,
                    role: form.state.role,
                  }}
                />
                <DiscoButton
                  leftIcon={"camera"}
                  color={"grey"}
                  className={classNames(hoverStyles.showable, classes.uploadButton)}
                />
              </DiscoContainerButton>
            ),
            // Rerender the avatar if the name or color changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [form.state.name, form.state.color]
          )}
        </MediaLibraryButton>
        {form.state.assetId && (
          <DiscoTextButton onClick={handleRemoveImage}>{"Remove Image"}</DiscoTextButton>
        )}
      </div>
    </DiscoFormControl>
  )

  function handleChangeImage(result: MediaResult) {
    form.state.avatarURL = result.url
    form.state.assetId = result.id
  }

  function handleRemoveImage() {
    form.state.avatarURL = null
    form.state.assetId = null
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "fit-content",
    alignItems: "flex-start",
  },
  avatarContainer: {
    position: "relative",
  },
  uploadButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
}))

export default observer(MemberGroupAvatarInput)
