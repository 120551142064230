import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isProductLevelRoute } from "@/core/route/util/routeUtils"
import ProductDeleteConfirmationModal, {
  ProductDeleteConfirmationModalPreloadedRequest,
} from "@/product/delete/ProductDeleteConfirmationModal"
import { ProductDeleteConfirmationModalPreloadedQuery } from "@/product/delete/__generated__/ProductDeleteConfirmationModalPreloadedQuery.graphql"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"
import { useQueryLoader } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"
import { ProductDeleteButtonMutation } from "./__generated__/ProductDeleteButtonMutation.graphql"

interface ProductDeleteButtonProps {
  // Hacky for compatibility with Django. Should be a fragment key in the future.
  product: {
    id: GlobalID
    type: ProductType
    name: string
    slug?: string
  }
  children: OverridableDiscoButtonChildren
  onDelete?(): void
}

function ProductDeleteButton({
  product,
  onDelete,
  children,
  ...props
}: ProductDeleteButtonProps) {
  const [showModal, setShowModal] = useState(false)
  const experienceLabel = useLabel("admin_experience")
  const activeOrganization = useActiveOrganization()!
  const history = useHistory()
  const invalidateSidebar = ProductUtils.useInvalidateSidebar()

  const form = useFormStore<ProductDeleteButtonMutation>(
    graphql`
      mutation ProductDeleteButtonMutation($input: DeleteExperienceInput!) {
        response: deleteExperience(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      experienceId: product.id,
    }
  )

  const [queryReference, loadQuery] =
    useQueryLoader<ProductDeleteConfirmationModalPreloadedQuery>(
      ProductDeleteConfirmationModalPreloadedRequest
    )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} {...props}>
        {children}
      </OverridableDiscoButton>
      {queryReference && (
        <ProductDeleteConfirmationModal
          isOpen={showModal}
          onClose={closeModal}
          confirmButtonProps={{
            onClick: handleArchive,
            disabled: form.isSubmitting,
            shouldDisplaySpinner: form.isSubmitting,
          }}
          productQueryRef={queryReference}
        />
      )}
    </>
  )

  function openModal() {
    // reload the product query to get the latest data if a product is removed from a pathway
    loadQuery({ id: product.id }, { fetchPolicy: "network-only" })
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  async function handleArchive() {
    try {
      if (
        product.slug &&
        isProductLevelRoute(location.href, { productSlug: product.slug })
      ) {
        history.replace(
          generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug: product.slug,
          })
        )
      }
      const { didSave } = await form.submit(form.state, {
        updater: (store, payload) => {
          if (!payload.response?.node?.id) return

          const orgRecord = store.get(activeOrganization.id)
          if (!orgRecord) return

          ConnectionHandlerPlus.getConnections(
            orgRecord,
            "ProductCardGrid__products"
          ).forEach((connection) => connection.invalidateRecord())
          invalidateSidebar(store)
        },
      })
      if (!didSave) return

      displaySuccessToast({
        message: `${experienceLabel.singular} has been deleted`,
        testid: "ProductDeleteButton.confetti-emoji",
      })

      if (onDelete) onDelete()
      closeModal()
    } catch (error) {
      displayErrorToast(error)
    }
  }
}

export default ProductDeleteButton
