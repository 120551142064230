/**
 * @generated SignedSource<<20f1ccf77c64cd95219f1b9593fb7d33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MemberGroupVisibility = "admin_only" | "everyone" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useMemberGroupTagsList_OrganizationMembershipFragment$data = {
  readonly groupMemberships: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly memberGroup: {
          readonly id: string;
          readonly parentMemberGroupId: string | null;
          readonly visibility: MemberGroupVisibility;
          readonly " $fragmentSpreads": FragmentRefs<"MemberGroupTagFragment">;
        };
        readonly productId: string | null;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "useMemberGroupTagsList_OrganizationMembershipFragment";
};
export type useMemberGroupTagsList_OrganizationMembershipFragment$key = {
  readonly " $data"?: useMemberGroupTagsList_OrganizationMembershipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMemberGroupTagsList_OrganizationMembershipFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "groupMemberships"
        ]
      }
    ]
  },
  "name": "useMemberGroupTagsList_OrganizationMembershipFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "groupMemberships",
      "args": [
        {
          "kind": "Literal",
          "name": "groupKinds",
          "value": [
            "custom"
          ]
        }
      ],
      "concreteType": "MemberGroupMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "__useMemberGroupTagsList_OrganizationMembershipFragment_groupMemberships_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroupMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MemberGroup",
                  "kind": "LinkedField",
                  "name": "memberGroup",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "parentMemberGroupId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visibility",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "MemberGroupTagFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__useMemberGroupTagsList_OrganizationMembershipFragment_groupMemberships_connection(groupKinds:[\"custom\"])"
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
})();

(node as any).hash = "787b9c83cb80f32826b7129fc6e63012";

export default node;
