/**
 * @generated SignedSource<<cc9752c216e2aab15bf3cb9e6fb6bfe6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MailToProductMembersInput = {
  filters?: MailToProductMembersFilterInput | null;
  fromEmail: string;
  mailAll?: boolean | null;
  productId: string;
  productMembershipIds?: ReadonlyArray<string> | null;
};
export type MailToProductMembersFilterInput = {
  byMemberGroup?: string | null;
};
export type useEmailProductMembersMutation$variables = {
  input: MailToProductMembersInput;
};
export type useEmailProductMembersMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly mailTo: string | null;
  };
};
export type useEmailProductMembersMutation = {
  response: useEmailProductMembersMutation$data;
  variables: useEmailProductMembersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MailToProductMembersResponse",
    "kind": "LinkedField",
    "name": "mailToProductMembers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mailTo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEmailProductMembersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEmailProductMembersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3c7200d3c413416f002096105211e5cd",
    "id": null,
    "metadata": {},
    "name": "useEmailProductMembersMutation",
    "operationKind": "mutation",
    "text": "mutation useEmailProductMembersMutation(\n  $input: MailToProductMembersInput!\n) {\n  response: mailToProductMembers(input: $input) {\n    mailTo\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ee78197785229ff271265c12e876e5d";

export default node;
